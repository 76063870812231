<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="3">
          <v-radio-group v-model="transactionType" row @change="onChangeTransactionType">
            <v-radio label="AP" value="AP"></v-radio>
            <v-radio label="Forwarder" value="Other"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="6">
          <v-text-field
            flat
            outlined
            dense
            v-model="search"
            append-icon="mdi-magnify"
            placeholder="search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="purchasePayments" :search="search" dense>
        <template v-slot:item.no="{ item }">
          {{ purchasePayments.map(x => x).indexOf(item) + 1 }}
        </template>
        <template v-slot:item.paymentDate="{ item }">
          {{ formatDate(item.paymentDate) }}
        </template>
        <template v-slot:item.totalAmount="{ item }">
          {{ formatPrice(item.totalAmount) }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn small color="success" @click.stop="approval(item.id, 'Approved')" class="mr-2">
            <v-icon>
              mdi-check
            </v-icon>
          </v-btn>
          <!-- <v-btn small color="error" @click.stop="approval(item.id, 'Rejected')">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn> -->
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">
            Reset
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <dialog-approval-purchase-payment
      :dialog="dialog"
      :form="purchasePayment"
      :listBank="listBank"
      :type="type"
      :transactionType="transactionType"
      @approved="updateRemark"
      @close="close"
    ></dialog-approval-purchase-payment>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import DialogApprovalPurchasePayment from "@/components/DialogApprovalPurchasePayment.vue";
export default {
  name: "purchase-payment-approval",
  components: {
    DialogApprovalPurchasePayment,
  },
  data: () => ({
    search: null,
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Date",
        value: "paymentDate",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Vocher Number",
        value: "voucherNumber",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Method",
        value: "methodPayment",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Vendor",
        value: "vendorName",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 6,
        text: "Total",
        value: "totalAmount",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "Action",
        value: "action",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    dialog: false,
    purchasePayment: {},
    listBank: [],
    type: "",
    transactionType: "",
  }),

  computed: {
    ...mapState("purchasePayment", ["purchasePayments"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([this.getBanks()])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getApproval() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("purchasePayment/getApproval")
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getApprovalOther() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("purchasePayment/getApprovalOther")
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async onChangeTransactionType(val) {
      if (val === "Other") {
        this.getApprovalOther();
      } else {
        this.getApproval();
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onClickRow(item) {
      if (this.transactionType === "Other") {
        this.getPurchasePaymentOther(item.id);
      } else {
        this.getPurchasePayment(item.id);
      }
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    formatDate(date) {
      return moment(date).format("DD-MM-yyyy");
    },
    async getBanks() {
      await this.$store.dispatch("chartOfAccount/getBanks").then(response => {
        this.listBank = response.data;
      });
    },
    async getPurchasePayment(id) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("purchasePayment/getById", id)
        .then(response => {
          this.purchasePayment = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getPurchasePaymentOther(id) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("purchaseOtherPayment/getById", id)
        .then(response => {
          this.purchasePayment = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    approval(id, type) {
      this.type = type;
      if (this.transactionType === "Other") {
        this.getPurchasePaymentOther(id);
      } else {
        this.getPurchasePayment(id);
      }
      this.dialog = true;
    },
    async updateRemark(item) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("purchasePayment/approval", {
          transactionId: item.id,
          type: item.type,
          remark: item.remark,
          referenceBank: item.referenceBank,
          transactionType: item.transactionType,
        })
        .then(() => {
          if (this.transactionType === "Other") {
            this.getApprovalOther();
          } else {
            this.getApproval();
          }
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
};
</script>

<style></style>
