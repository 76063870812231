<template>
  <v-dialog v-model="dialog" max-width="1200" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ type }} Purchase Payment {{ transactionType }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Vendor <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.vendorName }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="!hidden">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Voucher Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.voucherNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Method<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span v-if="!hidden">{{ form.methodPayment }}</span>
                <span v-if="hidden">{{ form.paymentMethod }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pay Date<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span v-if="!hidden">{{ formatDate(form.payDate) }}</span>
                <span v-if="hidden">{{ formatDate(form.paymentDate) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="!hidden">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Round<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.round }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="!hidden">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Round Amount<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.roundAmount }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Charges<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ formatPrice(form.bankCharge) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.remark }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab>
                <v-icon>
                  mdi-information
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"></v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Invoice Detail <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.invoiceDtoList"
                        disable-pagination
                        hide-default-footer
                        v-if="!hidden"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ form.invoiceDtoList.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.totalAmountAfterTax="{ item }">
                          {{ formatPrice(item.totalAmountAfterTax) }}
                        </template>
                        <template v-slot:item.rate="{ item }">
                          {{ formatPrice(item.rate) }}
                        </template>
                        <template v-slot:item.totalInvoiceIDR="{ item }">
                          {{ formatPrice(item.totalInvoiceIDR) }}
                        </template>
                        <template v-slot:item.totalInvoicePaid="{ item }">
                          {{ formatPrice(item.totalInvoicePaid) }}
                        </template>
                        <template v-slot:item.totalInvoicePaidIDR="{ item }">
                          {{ formatPrice(item.totalInvoicePaidIDR) }}
                        </template>
                        <template v-slot:item.fullPayment="{ item }">
                          {{ item.fullPayment ? "YES" : "NO" }}
                        </template>
                      </v-data-table>
                      <v-data-table
                        :headers="headers"
                        :items="form.invoices"
                        disable-pagination
                        hide-default-footer
                        v-if="hidden"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ form.invoices.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.totalAmountAfterTax="{ item }">
                          {{ formatPrice(item.totalAmountAfterTax) }}
                        </template>
                        <template v-slot:item.rate="{ item }">
                          {{ formatPrice(item.rate) }}
                        </template>
                        <template v-slot:item.totalInvoiceIDR="{ item }">
                          {{ formatPrice(item.totalInvoiceIDR) }}
                        </template>
                        <template v-slot:item.totalInvoicePaid="{ item }">
                          {{ formatPrice(item.invoicePaid) }}
                        </template>
                        <template v-slot:item.totalInvoicePaidIDR="{ item }">
                          {{ formatPrice(item.totalInvoicePaidIDR) }}
                        </template>
                        <template v-slot:item.fullPayment="{ item }">
                          {{ item.fullPayment ? "YES" : "NO" }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6" class="pb-0"> </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Bank / Cash <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headerBank"
                        :items="form.accounts"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:item.no="{ item }">
                          {{ form.accounts.indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.amount="{ item }">
                          {{ formatPrice(item.amount) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="mx-4 my-4"></v-divider>
      <v-card-text>
        <v-row v-if="type === 'Approved'">
          <v-col cols="12" sm="12" md="6" lg="4">Bank Reference</v-col>
          <v-col cols="12" sm="12" md="6" lg="8">
            <v-form lazy-validation v-model="valid" ref="form">
              <v-text-field
                dense
                outlined
                flat
                v-model="referenceBank"
                :rules="[v => !!v || 'Reference Bank is required']"
              ></v-text-field>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="type === 'Rejected'">
          <v-col cols="12" sm="12" md="6" lg="4">Remark</v-col>
          <v-col cols="12" sm="12" md="6" lg="8">
            <v-form lazy-validation v-model="valid" ref="form">
              <v-textarea
                outlined
                flat
                rows="3"
                v-model="remark"
                :rules="[v => !!v || 'Remark is required']"
              ></v-textarea>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="approval" class="mr-2">
          Save
        </v-btn>
        <v-btn color="error" @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
export default {
  name: "dialog-approval-purchase-payment",
  props: {
    dialog: Boolean,
    form: Object,
    listBank: Array,
    type: String,
    transactionType: String,
  },
  computed: {
    hidden() {
      if (this.transactionType === "Other") {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false,
          divider: true,
        },
        {
          text: "Invoice #",
          value: "invoiceNumber",
          sortable: false,
          divider: true,
        },
        {
          text: "Invoice Date",
          value: "invoiceDate",
          sortable: false,
          divider: true,
        },
        {
          text: "Currency",
          value: "currency",
          sortable: false,
          divider: true,
        },
        {
          text: "Amount",
          value: "totalAmountAfterTax",
          sortable: false,
          divider: true,
        },
        {
          text: "Rate (Invoice)",
          value: "rate",
          sortable: false,
          divider: true,
        },
        {
          text: "Total Invoice (RP)",
          value: "totalInvoiceIDR",
          sortable: false,
          divider: true,
        },
        {
          text: "Full Payment",
          value: "fullPayment",
          sortable: false,
          divider: true,
        },
      ],
      headerBank: [
        {
          text: "No",
          value: "no",
          sortable: false,
          divider: true,
        },
        {
          text: "Account Number",
          value: "accountNumber",
          sortable: false,
          divider: true,
        },
        {
          text: "AccountName",
          value: "accountName",
          sortable: false,
          divider: true,
        },
        {
          text: "Amount",
          value: "amount",
          sortable: false,
          divider: true,
        },
      ],
      referenceBank: "",
      remark: "",
      valid: true,
    };
  },
  methods: {
    approval() {
      if (this.$refs.form.validate()) {
        this.$emit("approved", {
          id: this.form.id,
          type: this.type,
          referenceBank: this.referenceBank,
          remark: this.remark,
          transactionType: this.transactionType,
        });
        this.close();
      }
    },
    close() {
      this.$emit("close");
    },
    bankName(id) {
      const bank = this.listBank.find(x => x.accountId === id);
      const name = bank ? bank.accountName : "";
      return name;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD-MM-yyyy");
    },
  },
};
</script>

<style></style>
